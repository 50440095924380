import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../common/utils/stimulus"

// slideshow
class HeroSlideshow extends Controller {
  static targets = [`slide`, `indicator`]

  currentIndex = 0
  loop = null

  connect() {
    this.init()
  }

  disconnect() {
    window.clearTimeout(this.loop)
  }

  init = () => {
    for (let i = 0; i < this.slideTargets.length; i++) {
      this.slideTargets[i].classList.remove(`--active`)
      this.indicatorTargets[i].classList.remove(`--active`)
    }

    this.currentIndex++

    if (this.currentIndex > this.slideTargets.length) {
      this.currentIndex = 1
    }

    this.slideTargets[this.currentIndex - 1].classList.add(`--active`)
    this.indicatorTargets[this.currentIndex - 1].classList.add(`--active`)

    this.loop = window.setTimeout(() => {
      this.init()
    }, 4300)
  }
}

Stimulus.register(`hero-slideshow`, HeroSlideshow)
