import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../common/utils/stimulus"

// topics

class OnboardingPageTopics extends Controller {
  static values = { expanded: Boolean }

  static targets = [`childInput`]

  toggleExpanded() {
    this.expandedValue = !this.expandedValue
  }

  toggleChecked(e) {
    this.expandedValue = e.currentTarget.checked

    if (!e.currentTarget.checked && this.hasChildInputTarget)
      this.childInputTargets.forEach((c) => (c.checked = false))
  }
}

Stimulus.register(`onboarding-page-topics`, OnboardingPageTopics)
