import { Controller } from "@hotwired/stimulus"
import fetchJsonp from "fetch-jsonp"
import { analyticizeEvent } from "@oddcamp/analytics"

import Stimulus from "../../common/utils/stimulus"

class Newsletter extends Controller {
  static values = { response: String, error: String }

  static targets = [`successNote`, `errorNote`, `button`]

  #loading = false

  submit(e) {
    e.preventDefault()

    if (this.#loading) return

    this.#loading = true
    this.respondedValue = ``

    const params = new URLSearchParams(new FormData(this.element)).toString()
    const url = `${this.element.action.replace(`post`, `post-json`)}&${params}`

    fetchJsonp(url, {
      jsonpCallback: `c`,
    })
      .then((r) => r.json())
      .then((data) => {
        if (data.result == `success`) {
          this.responseValue = `success`
          this.successNoteController.setContent(data.msg)

          analyticizeEvent({
            data: {
              name: `newsletter_signup_success`,
            },
          })
        } else {
          this.responseValue = `error`
          this.errorNoteController.setContent(data.msg.substring(4))

          analyticizeEvent({
            data: {
              name: `newsletter_signup_error`,
            },
          })
        }

        this.buttonController.setDisabled(false)
        this.#loading = false
      })
      .catch(() => {
        this.responseValue = `error`
        this.#loading = false
        this.errorNoteController.setContent(this.errorValue)
        this.buttonController.setDisabled(false)

        analyticizeEvent({
          data: {
            name: `newsletter_signup_error`,
          },
        })
      })
  }

  get successNoteController() {
    return this.application.getControllerForElementAndIdentifier(
      this.successNoteTarget,
      `note`
    )
  }

  get errorNoteController() {
    return this.application.getControllerForElementAndIdentifier(
      this.errorNoteTarget,
      `note`
    )
  }

  get buttonController() {
    return this.application.getControllerForElementAndIdentifier(
      this.buttonTarget,
      `button`
    )
  }
}

Stimulus.register(`newsletter`, Newsletter)
