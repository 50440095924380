import { Controller } from "@hotwired/stimulus"
import Swiper, { Navigation, Pagination, A11y, Keyboard } from "swiper"

import Stimulus from "../../common/utils/stimulus"

// item

class SlidecardsCards extends Controller {
  static values = { prevMsg: String, nextMsg: String, paginationMsg: String }

  static targets = [`swiper`, `prev`, `next`, `pagination`]

  connect() {
    new Swiper(this.swiperTarget, {
      modules: [Navigation, Pagination, A11y, Keyboard],
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
      },
      navigation: {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      a11y: {
        prevSlideMessage: this.prevMsgValue,
        nextSlideMessage: this.nextMsgValue,
        paginationBulletMessage: `${this.paginationMsgValue} {{index}}`,
      },
      keyboard: {
        enabled: true,
      },
    })
  }
}

Stimulus.register(`slidecards-cards`, SlidecardsCards)
