import { Controller } from "@hotwired/stimulus"

import Stimulus from "../utils/stimulus"

class Note extends Controller {
  static targets = [`content`]

  setContent(content) {
    this.contentTarget.innerHTML = content
  }
}

Stimulus.register(`note`, Note)
