import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../common/utils/stimulus"

class Onboarding extends Controller {
  static targets = [`button`]

  membershipStep() {
    const radioButtons = document.querySelectorAll(
      `.onboarding-page-membership input[type="radio"]:checked`
    )
    const buttonDataset = this.buttonTarget.dataset

    if (radioButtons[0].value === `true`) {
      window.location.href = buttonDataset.yes
    } else {
      window.location.href = buttonDataset.no
    }
  }
}

Stimulus.register(`onboarding`, Onboarding)
