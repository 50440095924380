import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../common/utils/stimulus"

// item

class FaqItem extends Controller {
  static values = { expanded: Boolean }

  toggle(e) {
    this.expandedValue = !this.expandedValue
    e.currentTarget.ariaExpanded = this.expandedValue
  }
}

Stimulus.register(`faq-item`, FaqItem)
